<template>
  <div class="red--text" v-if="isAuthenticated">
    <h2>You are not authorized to access that page!</h2>
    <br/>
    <h3>Your access token might have expired.</h3>
    <h3>To get a new token, please reload the page.</h3>
    <br/>
    <br/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { STORE_GETTER_AUTH_OIDC_IS_AUTHENTICATED } from '@/store/store-types';

export default {
  name: 'forbidden',
  computed: {
    ...mapGetters({
      isAuthenticated: STORE_GETTER_AUTH_OIDC_IS_AUTHENTICATED
    })
  }
};
</script>
